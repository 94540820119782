<template>
  <v-row>
    <!-- kitchen sink -->
    <v-col cols="12">
      <v-card>
        <v-card-title>
          {{ `${$t('menu.gestion')} / ${$t('menu.paysEmit')}` }}
        </v-card-title>
        <v-card-text><PaysList /></v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import PaysList from './tablas/PaysList.vue'

export default {
  components: {
    PaysList,
  },
  data() {
    return {}
  },
}
</script>
