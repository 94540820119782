var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-text',[_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-text-field',{attrs:{"label":_vm.$t('lbl.itemsPerPage'),"type":"number","min":"5","hide-details":"","dense":"","outlined":""},on:{"input":function($event){return _vm.changeCantItems()}},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1)],1)],1),_c('v-divider'),_c('v-form',{staticClass:"multi-col-validation px-5 py-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('strong',[_vm._v(_vm._s(_vm.$t('lbl.afiliate')))]),_c('v-autocomplete',{staticClass:"pt-2",attrs:{"items":_vm.companies_pay,"search-input":_vm.companyPaySearch,"hide-details":"","hide-selected":"","label":_vm.$t('lbl.afiliate'),"outlined":"","dense":"","item-text":"name","item-value":"id"},on:{"update:searchInput":function($event){_vm.companyPaySearch=$event},"update:search-input":function($event){_vm.companyPaySearch=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('lbl.afiliate'))+" ")])],1)]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(item.name)}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)]}}]),model:{value:(_vm.search.company_pay_id),callback:function ($$v) {_vm.$set(_vm.search, "company_pay_id", $$v)},expression:"search.company_pay_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('strong',[_vm._v(_vm._s(_vm.$t('menu.paysEmit')))]),_c('v-autocomplete',{staticClass:"pt-2",attrs:{"items":_vm.codesPays,"search-input":_vm.searchCodePays,"hide-details":"","hide-selected":"","label":_vm.$t('lbl.code'),"outlined":"","dense":""},on:{"update:searchInput":function($event){_vm.searchCodePays=$event},"update:search-input":function($event){_vm.searchCodePays=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('lbl.code'))+" ")])],1)]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(item)}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)]}}]),model:{value:(_vm.search.code),callback:function ($$v) {_vm.$set(_vm.search, "code", $$v)},expression:"search.code"}})],1)],1)],1),_c('v-divider'),_c('v-form',{staticClass:"multi-col-validation px-5 py-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"1"}},[(_vm.search.company_pay_id || _vm.search.code)?_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","outlined":"","small":"","color":"error"},on:{"click":function($event){return _vm.clearSearch()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiEraserVariant)+" ")])],1):_vm._e()],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"9"}}),_c('v-col',{staticClass:"text-lowercase",attrs:{"cols":"12","md":"2"}},[_vm._v(" "+_vm._s(_vm.totalF)+" "+_vm._s(_vm.$t('lbl.de'))+" "+_vm._s(_vm.totalItems)+" "),_c('span',{staticClass:"text-lower"},[_vm._v(_vm._s(_vm.$t('menu.paysEmit')))])])],1)],1),_c('v-divider'),_c('PaysEmitidos',{attrs:{"user":_vm.user,"pays":_vm.pays,"states":_vm.states,"states-ctas":_vm.statesCtas,"payments":_vm.payments},on:{"loadAll":function($event){return _vm.initAll()}}}),_c('v-card-text',{staticClass:"pt-2"},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"lg":"12","cols":"12"}},[_c('v-pagination',{attrs:{"length":_vm.pagination.total,"total-visible":"5"},on:{"input":_vm.onPageChange},model:{value:(_vm.pagination.current),callback:function ($$v) {_vm.$set(_vm.pagination, "current", $$v)},expression:"pagination.current"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }