<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div>
    <v-card-text>
      <v-row>
        <v-spacer></v-spacer>
        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="itemsPerPage"
            :label="$t('lbl.itemsPerPage')"
            type="number"
            min="5"
            hide-details
            dense
            outlined
            @input="changeCantItems()"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider></v-divider>
    <v-form class="multi-col-validation px-5 py-5">
      <v-row>
        <!--<v-col
          cols="12"
          md="3"
        >
          <strong>{{ $t('lbl.modality') }}</strong>
          <v-select
            v-model="search.method_pay_id"
            :items="payments"
            :label="$t('lbl.wayMethod')"
            outlined
            dense
            hide-details
            item-text="name"
            item-value="id"
            class="pt-2"
          ></v-select>
        </v-col>-->
        <v-col
          cols="12"
          md="3"
        >
          <strong>{{ $t('lbl.afiliate') }}</strong>
          <v-autocomplete
            v-model="search.company_pay_id"
            :items="companies_pay"
            :search-input.sync="companyPaySearch"
            hide-details
            hide-selected
            :label="$t('lbl.afiliate')"
            outlined
            dense
            item-text="name"
            item-value="id"
            class="pt-2"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.afiliate') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <strong>{{ $t('menu.paysEmit') }}</strong>
          <v-autocomplete
            v-model="search.code"
            :items="codesPays"
            :search-input.sync="searchCodePays"
            hide-details
            hide-selected
            :label="$t('lbl.code')"
            outlined
            dense
            class="pt-2"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.code') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-form>
    <v-divider></v-divider>
    <v-form class="multi-col-validation px-5 py-5">
      <v-row>
        <v-col
          cols="12"
          md="1"
        >
          <v-btn
            v-if="search.company_pay_id || search.code"
            class="mx-2"
            fab
            outlined
            small
            color="error"
            @click="clearSearch()"
          >
            <v-icon small>
              {{ icons.mdiEraserVariant }}
            </v-icon>
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="9"
          class="text-center"
        >
        </v-col>
        <v-col
          cols="12"
          md="2"
          class="text-lowercase"
        >
          {{ totalF }} {{ $t('lbl.de') }} {{ totalItems }} <span class="text-lower">{{ $t('menu.paysEmit') }}</span>
        </v-col>
      </v-row>
    </v-form>

    <v-divider></v-divider>

    <PaysEmitidos
      :user="user"
      :pays="pays"
      :states="states"
      :states-ctas="statesCtas"
      :payments="payments"
      @loadAll="initAll()"
    />

    <v-card-text class="pt-2">
      <v-row>
        <v-col
          lg="12"
          cols="12"
          class="d-flex justify-end"
        >
          <v-pagination
            v-model="pagination.current"
            :length="pagination.total"
            total-visible="5"
            @input="onPageChange"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
  mdiAirplaneTakeoff,
  mdiCar,
  mdiBed,
  mdiClose,
  mdiEye,
  mdiEraserVariant,
  mdiSendOutline,
} from '@mdi/js'

// eslint-disable-next-line import/no-unresolved
// import useAppConfig from '@core/@app-config/useAppConfig'

import PaysEmitidos from '../utils/PaysEmitidos.vue'

// import Cotizations from '../tabs/Cotizations.vue'

export default {
  /* setup() {
    const { menuIsVerticalNavMini } = useAppConfig()

    return {
      menuIsVerticalNavMini,
    }
  }, */

  components: {
    PaysEmitidos,
  },

  data() {
    return {
      currentTab: 0,
      isLoading: true,
      search: {
        proximas: false,
        activas: false,
        state: null,
        afiliate: null,
      },
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
        wheelSpeed: 0.7,
      },
      pays: [],
      pagination: {
        current: 1,
        total: 0,
      },
      totalItems: 0,
      totalF: 0,
      itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
        mdiAirplaneTakeoff,
        mdiCar,
        mdiBed,
        mdiClose,
        mdiEye,
        mdiEraserVariant,
        mdiSendOutline,
      },
      isDialogVisible: false,
      isDialogVisibleDelete: false,
      item: {},
      itemId: null,
      abilities: [],
      abilityList: [],
      states: [],
      statesCtas: [],
      payments: [],
      checkPermiso: 1,
      loading: false,
      loadingPay: false,
      permisos: sessionStorage.getItem('permisos_auth'),
      is_nivel_propietario: false,
      user: {},
      codesList: [],
      codesPays: [],
      searchCodePays: null,
      companiesPayList: [],
      companies_pay: [],
      companyPaySearch: null,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      menuFrom: false,
      menuTo: false,
    }
  },
  computed: {
    ...mapState({
      itemsCheckCobros: state => state.app.itemsCheckCobros,
      posCotizador: state => state.app.posCotizador,
    }),
    computedFromDateFormatted() {
      return this.search.from ? this.$moment(this.search.from).format('D-MMM-YY') : ''
    },
    computedToDateFormatted() {
      return this.search.to ? this.$moment(this.search.to).format('D-MMM-YY') : ''
    },
  },
  watch: {
    searchCodePays(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterCodesPays(val.toLowerCase())
      } else {
        this.codesPays = []
      }
    },

    companyPaySearch(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterCompanyPay(val.toLowerCase())
      } else {
        this.companies_pay = []
      }
    },
  },
  mounted() {
    // PARA Q NAZCA EL MENU IZQ OCULTO
    // this.menuIsVerticalNavMini = true
  },
  created() {
    this.profile()
    this.initAll()

    this.getStates()
    this.getStatesCtas()
    this.getPayments()

    this.getCompaniesPays()
    this.getCodes()
  },
  methods: {
    ...mapMutations(['setPosCotizador']),
    filterCompanyPay(v) {
      this.companies_pay = []
      if (v === '') {
        this.companies_pay = []
      } else {
        this.companies_pay = this.companiesPayList.filter(e => e.name.toLowerCase())
      }
    },
    filterCodesPays(v) {
      this.codesPays = []
      if (v === '') {
        this.codesPays = []
      } else {
        this.codesPays = this.codesList.filter(e => e.toLowerCase())
      }
    },
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          } else if (response.data.data.nivel === -1) {
            this.is_nivel_propietario = true
          }
          this.user = response.data.data
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    getStates() {
      this.axios
        .get('state_reservations?per_page=10000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.states = res.data.data
        })
    },
    getStatesCtas() {
      this.axios
        .get('state_ctas?per_page=10000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.statesCtas = res.data.data
        })
    },
    getPayments() {
      this.axios
        .get('nom_methods_pay?per_page=10000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.payments = res.data.data
        })
    },

    getCompaniesPays() {
      this.axios
        .post(
          'pays-emitidos/search-list',
          {
            parameter: 'company_pay',
          },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success) {
            this.companiesPayList = res.data.data.list
          }
        })
    },
    getCodes() {
      this.axios
        .post(
          'pays-emitidos/search-list',
          {
            parameter: 'code',
          },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success) {
            this.codesList = res.data.data.list
          }
        })
    },

    getPays() {
      const json = {
        page: this.pagination.current,
        per_page: this.itemsPerPage,
        search: this.search,
      }
      this.axios
        .post('pays-emitidos/list', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            // console.log(res)

            this.pays = res.data.data

            this.pagination.current = res.data.meta.current_page
            this.pagination.total = res.data.meta.last_page

            this.totalF = res.data.meta.total
          }
        })
    },
    changeCantItems() {
      this.getPays()
    },
    searchAll() {
      this.pagination.current = 1
      this.getPays()
    },
    getPaysAll() {
      const json = {
        page: this.pagination.current,
        per_page: 1000,
        search: this.search,
      }
      this.axios
        .post('pays-emitidos/list', json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.totalItems = res.data.meta.total
          }
        })
    },
    onPageChange() {
      this.getPays()
    },
    reseat() {
      this.isDialogVisible = false
      this.isDialogVisibleDelete = false
      this.item = {}
      this.itemId = null
      this.loading = false
      this.loadAll()
    },
    loadAll() {
      this.getPays()
    },
    initAll() {
      this.getPays()
      this.getPaysAll()
    },

    clearSearch() {
      this.search = {}
      this.loadAll()
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
